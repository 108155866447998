export const FIXED_COMPONENT_TYPES = {
    MODAL:'modal',
    DRAWER:'drawer',
};

export const MODALS_BY_ID = {
    EXAMPLE_MODAL:'EXAMPLE_MODAL',
};

export const DRAWERS_BY_ID = {
    EXAMPLE_DRAWER:'EXAMPLE_DRAWER',
};
